import _unistUtilVisit from "unist-util-visit";
import _util from "@mdx-js/util";
var exports = {};
const visit = _unistUtilVisit;
const {
  isComment,
  getCommentContents
} = _util;

exports = _options => tree => {
  visit(tree, "jsx", node => {
    if (isComment(node.value)) {
      node.type = "comment";
      node.value = getCommentContents(node.value);
    }
  });
  return tree;
};

export default exports;